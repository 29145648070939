import React from "react";
import {
  Box,
  Image,
  Text,
  Link,
  PinInput,
  PinInputField,
  HStack,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Spinner,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

// METAGAME IMAGES ON IPFS
// https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/
// https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/
// https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/
// https://bafybeiggmevfo3wujbvt3xqcvaudhrbs5wug7heigsxnnbkueburlefena.ipfs.infura-ipfs.io/
// https://bafybeiaj5zzzxd6sj6xahk5s75frcwnyo7kkfuyq3lxflflv2al45xebtu.ipfs.infura-ipfs.io/
// https://bafybeifyn3uk25iitgdmqz25a6xbkbwf72os2ssogayyllsrctm5ify23q.ipfs.infura-ipfs.io/
// https://bafybeiduvjqictrruk3zqbxnyep3egjmqqtosoksklvpm7cna3l7q7z4xy.ipfs.infura-ipfs.io/
// https://bafybeiaa4sm4arxmcpa3cxk4wav3nlvdjfry2eayba4sleg6m4qenyhe4y.ipfs.infura-ipfs.io/
// https://bafybeihyswfympur6dq4qswdfq5kwb6e5a2wmi4t4gvxbmxksxhkyc4mhy.ipfs.infura-ipfs.io/
// https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/




// FORM DATA AND SENT TO DISCORD WEBHOOK
// Use the webhook id and token, to get these informations go to Server Settings>Integrations>Webhooks and click on the pretended webhook and copy the webhook URL. Paste the URL on google to see the webhook's ID and Token.

// const id = '';
// const token = '';
// const webhook = new Discord.WebhookClient(id, token);
// webhook.send('Your message');

// form = document.getElementById("submit-form");
// sucessMsg = document.getElementById("success");

// function sucess(event) {
// sucessMsg.style.display = "inline"
// event.preventDefault()
// console.log("form data:", getData())

// }

// function getData()
// {
// const formData = new FormData(form),
// ret = {};

// for(let [name, value] of formData.entries())
// ret[name] = value;

//  return ret;
//  }

// form.addEventListener("submit" , sucess )

function FormLayout12() {
  const [show, setShow] = React.useState(false);
const handleClick = () => setShow(!show);
  return (
    <div style={{ backgroundImage: `url(${background})` }}>

<Box
                  backgroundImage="url('https://bafybeihx67d3lfjiaubglcdijganeolkkagxegtt6wuztptgamnpjo2xvi.ipfs.infura-ipfs.io/')"
                  // backgroundImage="url('https://bafybeihlvdhwtb54nccbh3on3is2jwjlpzijhmb4jxftgadlfk45rcdd6a.ipfs.infura-ipfs.io/')"
                  
          
                  backgroundSize="cover"
                  backgroundPosition="cover"
                  backgroundRepeat="no-repeat"
                  opacity="0.99"
                  p={4} 
                  display={{ md: "flex" }}>      
                  
                  <Box flexShrink={0}>
        <Image
        maxW="200px"
          // borderRadius="lg"
          width={{ md: 40 }}
          src=
          // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
            // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
            // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
            // " https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "
            // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
            // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
            // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
            // " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
            // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
            // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
            // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
            // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
            // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
            // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
            // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
            // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
            // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
            // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
            " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
            // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"

          alt=" MetaCollab "
          />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
        <Text
          fontWeight="bold"
          textTransform="proper"
          fontSize="lg"
          letterSpacing="wide"
          color="cyan"
        >
          (Optional) Set passcode for deterministic transaction
        </Text>
        <Text mt={2} color="gray.500">
          Set a four-digit passcode to execute a deterministic signature and
          transaction.
        </Text>
        <br />
        {/* <Link
          mt={1}
          color="red.400"
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Numbers Only Pincode
        </Link>
        <HStack>
          <PinInput size="md">
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
          <Button colorScheme="blue" size="sm">
            Submit
          </Button>
        </HStack>
        <br /> */}
        <Link
          mt={1}
          color="gray.300"
          display="block"
          fontSize="md"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Set an Alphanumeric Passcode
        </Link>
        <HStack>
          {/* <PinInput size="md" type="alphanumeric"> */}
          <PinInput size="md" type="alphanumeric" mask>
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>
        <br />
        <Link
          mt={1}
          color="gray.300"
          display="block"
          fontSize="md"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Repeat Alphanumeric Passcode
        </Link>
        <HStack>
          {/* <PinInput size="md" type="alphanumeric"> */}
            <PinInput size="md" type="alphanumeric" mask>
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
          <Button colorScheme="blue" size="sm">
            Submit
          </Button>
        </HStack>
        <br />
          <InputGroup size="md" w="400px">
            <Input
              pr="4.5rem"
              _placeholder={{ color: 'cyan' }}
              type={show ? "text" : "password"}
              placeholder=" Enter optional passcode (salt) "
            />
            <InputRightElement width="4.5rem">
              <Button
                h="1.75rem"
                colorScheme="messenger"
                size="sm"
                onClick={handleClick}
              >
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Stack mt="5" justify="right" direction="row" spacing={4}>
                    <Spinner
                      thickness="2px"
                      speed="2s"
                      emptyColor="gray.200"
                      color="pink.500"
                      size="lg"
                    />
                    <Spinner
                      thickness="2px"
                      speed="3s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="lg"
                    />
                    <Spinner
                      thickness="2px"
                      speed="4s"
                      emptyColor="gray.200"
                      color="purple.500"
                      size="lg"
                    />
                  </Stack>
                  <Stack justify="right" direction="row" spacing={4}>
                    <Text mt={2} color="gray.100">
                      Please wait while your transaction processes. It may take
                      a while.
                    </Text>
                  </Stack>
      </Box>
    </Box>
    </ div >    
  );
}
export default FormLayout12;
