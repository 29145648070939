import React, { useState } from "react";
import {
  Button,
  Flex,
  Input,
  HStack,
  Image,
  Link,
  VStack,
  Table,
  TableCaption,
  Box,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  IconButton,
  Spinner,
  Stack,
  Text,
  Divider,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

const FieldsTable = ({ fields, deleteItem, ...props }) => (
  <Table {...props}>
    <TableCaption placement="top">data</TableCaption>
    <Thead>
      <Tr>
        <Th> MetaCollabKey </Th>
        <Th> Web3Value </Th>
        <Th> MetaCollab Functions </Th>
      </Tr>
    </Thead>
    <Tbody>
      {fields.map(({ key, value }, idx) => (
        <Tr key={idx}>
          <Td> 300 </Td>
          <Td> 300 </Td>
          <Td>
            <IconButton
              colorScheme="white"
              icon={<DeleteIcon />}
              onClick={() => deleteItem(key)}
            />
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);
const ObjectCreator1 = (props) => {
  const [fields, setFields] = useState([]);
  const deleteItem = (e) => {
    let fieldsCurrent = [...fields];
    const index = fields.indexOf(fields.find((x) => x.key === e));
    if (index > -1) {
      fieldsCurrent.splice(index, 1);
      setFields(fieldsCurrent);
      console.log("Removed", e);
    }
  };
  const nameProject = () => {
    const { value: name } = document.getElementById("name");
    const obj = { key: "name", value: name };
    setFields((fields) => [...fields, obj]);
  };
  function addItem() {
    let { value: key } = document.getElementById("key");
    let { value } = document.getElementById("value");
    const obj = { key, value };
    setFields((fields) => [...fields, obj]);
    document.getElementById("key").value = "";
    document.getElementById("value").value = "";
  }
  async function createObject() {
    const result = Object.fromEntries(
      fields.map((k) => [k["key"], k["value"]])
    );
    console.log(JSON.stringify(result));
    await props.createEntry(result["name"], JSON.stringify(result));
  }

  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      {/* <Flex p={2} flex={2} align={"center"} justify={"center"}> */}

      <Box
                  backgroundImage="url('https://bafybeihx67d3lfjiaubglcdijganeolkkagxegtt6wuztptgamnpjo2xvi.ipfs.infura-ipfs.io/')"
                  // backgroundImage="url('https://bafybeihlvdhwtb54nccbh3on3is2jwjlpzijhmb4jxftgadlfk45rcdd6a.ipfs.infura-ipfs.io/')"
                  
          
                  backgroundSize="cover"
                  backgroundPosition="cover"
                  backgroundRepeat="no-repeat"
                  opacity="0.99"
                  p={4} 
                  display={{ md: "flex" }}>   

      <Flex p={2} flex={2}
      
      
      
      >
        <Box flexShrink={0}>
          <Image
            maxW="200px"
            borderRadius="lg"
            mr="30px"
            width={{ md: 40 }}
            src=
            // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
              // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
              // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
              // " https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "
              // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
              // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
              // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
              // " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
              // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
              // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
              // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
              // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
              // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
              // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
              // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
              " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
              // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
              // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
              // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
              // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
  
            alt=" MetaCollab "          />
        </Box>
        <HStack spacing={4} w={"full"} maxW={"3xl"}>
          <Box >
            <Box mt={{ base: 4, md: 0 }} ml={{ sm: 6, md: 6 }}>
              
              <Text
                fontWeight="bold"
                textTransform="uppercase"
                fontSize="3xl"
                letterSpacing="wide"
                color="gray.200"
              >
                Attach Meta-Transaction to Gig
              </Text>
              <Link
                mt={1}
                display="block"
                fontSize="md"
                lineHeight="normal"
                fontWeight="semibold"
                href=" # "
              >
                These files should link to the underlying terms and conditions
                and any Gig-related content.
              </Link>
              <Link
                mt={1}
                display="block"
                fontSize="md"
                lineHeight="normal"
                fontWeight="semibold"
                href=" # "
              >
                Use no more than four "content locators or hashes"
              </Link>
            </Box>
            <Box>
              {fields.length > 0 ? (
                <Box>
                  <FieldsTable {...{ fields, deleteItem }} />
                  <VStack>
                    <Input
                      w="90%"
                      colorScheme="white"
                      variant="outline"
                      id="key"
                      placeholder="Classificator"
                    />
                    <Input
                      w="90%"
                      colorScheme="white"
                      variant="outline"
                      id="value"
                      placeholder="value"
                    />
                    <Button
                      colorScheme="white"
                      variant="outline"
                      onClick={addItem}
                    >
                      Add Property
                    </Button>
                    <Divider />
                    <Button
                      colorScheme="white"
                      variant="outline"
                      onClick={createObject}
                    >
                      Finish Object
                    </Button>
                  </VStack>
                  <Input
                    type="checkbox"
                    value={props.wrap}
                    checked={props.wrap}
                    onChange={() => props.setWrap((w) => !w)}
                  />
                  Wrap value in a DAG
                  <br />
                  <Button onClick={createObject}> Finish Object </Button>
                </Box>
              ) : (
                <Box>
                  <VStack>
                    <Input
                      mt="4"
                      w="40%"
                      variant="outline"
                      colorScheme="white"
                      id="name"
                      placeholder="Paste pre-built Gig reference here (in bytes)"
                    ></Input>
                    <Button
                      variant="outline"
                      colorScheme="green"
                      onClick={() => nameProject()}
                    >
                      {" "}
                      Attach Gig Reference (hash in bytes){" "}
                    </Button>
                  </VStack>

                  <Stack mt="5" justify="right" direction="row" spacing={4}>
                    <Spinner
                      thickness="2px"
                      speed="2s"
                      emptyColor="gray.200"
                      color="pink.500"
                      size="lg"
                    />
                    <Spinner
                      thickness="2px"
                      speed="3s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="lg"
                    />
                    <Spinner
                      thickness="2px"
                      speed="4s"
                      emptyColor="gray.200"
                      color="purple.500"
                      size="lg"
                    />
                  </Stack>
                  <Stack justify="right" direction="row" spacing={4}>
                    <Text mt={2} color="gray.100">
                      Please wait while your transaction processes. It may take
                      a while.
                    </Text>
                  </Stack>
                </Box>
              )}
            </Box>
          </Box>
        </HStack>
      </Flex>
      </Box>
    </div>
  );
};
export default ObjectCreator1;
